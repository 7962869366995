import { useMutation, useQuery, useQueryClient } from "react-query"
import { createCita, getHorariosDisponibles } from "../../services/clinica/cita"

const key = "citas"

export const useGetHorariosDisponibles = ({
  fechaCita,
  idEspecialista
}, enabled = true) =>
  useQuery([key, fechaCita, idEspecialista], getHorariosDisponibles, {
    enabled
  })

export const useCreateCita = () => {
  const queryClient = useQueryClient()
  return useMutation(body => createCita(body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

