import { useQuery } from 'react-query'
import {
  GetAll,
  GetById
} from '../services/alumno'

const key = 'alumno'

export const useGetAllAlumno = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled
  })



export const useGetAlumnoById = (codAlumno, enabled = true) =>
  useQuery(key, () => GetById(codAlumno), {
    enabled
  })
