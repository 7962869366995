import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid
} from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { DataGridStyle } from '../../../../components/DataGridStyle'
import Input from '../../../../components/Input'
import { useGetAlumnosBySesion, useRegistrarAlumnosInfo } from '../../../../hooks/useSesion'
import { ButtonSesionAlumno } from './ButtonSesionAlumno'
import { useAlertMessage } from '../../../../context/notifications'
// import { DataGridStyle } from '../../../../../components/DataGridStyle'

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction='up' ref={ref} {...props} />
// })

export const EstadoTicketAlumnoMapper = {
  1: 'Programada',
  4: 'Asistió',
  5: 'No Asistió'
}

export const EvaluacionMapper = {
  1: 'Deficiente',
  2: 'Regular',
  3: 'Bueno',
  4: 'Muy Bueno',
  5: 'Excelente'
}

export const LogroObjetivoMapper = {
  1: 'Logró todos los objetivos (100%)',
  2: 'Logró la mayoría de los objetivos (75-99%)',
  3: 'Logró algunos objetivos (50-74%)',
  4: 'Logró pocos objetivos (25-49%)',
  5: 'No logró objetivos (0-24%)'
}

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'nombre',
    headerName: 'Nombres',
    type: 'string',
    flex: 2
    // width: 180
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'estadoTicketAlumno',
    headerName: 'Asistencia',
    type: 'string',
    flex: 1,
    renderCell: params => {
      if (params.value === null) return 'Programada'
      return EstadoTicketAlumnoMapper[params.value]
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'evaluacion',
    headerName: 'Evaluación',
    type: 'string',
    // width: 180
    flex: 1,
    renderCell: params => {
      if (params.value === null) return 'No evaluado'
      return EvaluacionMapper[params.value]
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'logroObjetivo',
    headerName: 'Objetivos',
    type: 'string',
    // width: 180
    flex: 1,
    renderCell: params => {
      if (params.value === null) return 'No evaluado'
      return LogroObjetivoMapper[params.value]
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'comentarioAdicional',
    headerName: 'Comentarios adicionales',
    type: 'string',
    // width: 180
    flex: 3
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'acciones',
    type: 'actions',
    flex: 1,
    headerName: 'Acciones',
    cellClassName: 'acciones',
    getActions: ({ id, row }) => {
      return [
        <ButtonSesionAlumno alumnoData={row} />
      ]
    }
  }

  // {
  //   headerClassName: 'super-app-theme--header',
  //   field: 'semaforo',
  //   headerName: 'Semáforo',
  //   width: 370,
  //   type: 'string',
  //   renderCell: params => (
  //     <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
  //       <Circle sx={{ color: colorMapper[params.value] }} />
  //       <span>{semaforoMapper[params.value]}</span>
  //     </div>
  //   )
  // }
]

export const DetalleSesion = props => {
  const { onClose, selectedValue, open, codSesion } = props

  const { data: alumnos } = useGetAlumnosBySesion(codSesion)
  const { mutate, isLoading } = useRegistrarAlumnosInfo()
  const { showError, showSuccess } = useAlertMessage()

  const methods = useForm({
    defaultValues: {
      comentario: "",
    },
  })
  const { control } = methods

  const handleClose = () => {
    onClose(selectedValue)
  }

  const onSubmit = () => {
    const alumnosFormat = alumnos.map(({ codAlumno: codEstudiante, evaluacion, estadoTicketAlumno, logroObjetivo, comentarioAdicional }) => ({
      codEstudiante,
      evaluacion,
      estadoTicketAlumno,
      logroObjetivo,
      comentarioAdicional
    }))

    const formData = {
      codTicket: codSesion,
      comentario: methods.getValues("comentario"),
      alumnos: alumnosFormat,
    }
    mutate(formData, {
      onSuccess: () => {
        showSuccess('Datos registrados correctamente.')
        onClose()
      },
      onError: () => {
        showError('Ocurrio un error')
        onClose()
      }
    })
  }

  return (
    <>
      {open &&
        <Dialog
          onClose={handleClose}
          open={open}
          // TransitionComponent={Transition}
          // keepMounted
          width={'900px'}
          maxWidth={'lg'}
          fullWidth={true}
        >
          <DialogTitle>
            <h1 style={{ fontSize: '16pt', margin: '15px 0' }}>
              Detalles de la sesión
            </h1>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Label label='Código de la sesión' value='5012' />
              </Grid>
              <Grid item xs={12}>
                <Label label='Fecha' value='09/10/2024' />
              </Grid>
              <Grid item xs={4}>
                <Label label='Hora inicio' value='09:30 AM' />
              </Grid>
              <Grid item xs={4}>
                <Label label='Hora fin' value='10:30 AM' />
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <Input
                  id='comentario'
                  label='Comentarios'
                  control={control}
                  multiline
                  rows={2}
                />
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <h2
                  style={{
                    fontSize: '14pt',
                    fontWeight: 'bold',
                    margin: '7px 0 0 0'
                  }}
                >
                  Lista de Alumnos
                </h2>
              </Grid>
              <Grid item xs={12}>
                <DataGridStyle
                  height={400}
                  rows={alumnos || []}
                  columns={columns}
                  getRowId={row => row.codAlumno}
                  experimentalFeatures={{ newEditingApi: true }}
                  rowsPerPageOptions={[5]}
                  pageSize={5}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <LoadingButton loading={isLoading} variant='contained' onClick={onSubmit}>Guardar</LoadingButton>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      }
    </>
  )
}

const Label = ({ label, value }) => {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>{label}: </span>
      <span>{value}</span>
    </>
  )
}
