import { axiosBienestarClient } from "../../api/axios"

export const GetAllEspecialistas = async () => {
  const { data } = await axiosBienestarClient.get ('bienestar/especialista')
  return data
}

export const GetAllEspecialidades = async () => {
  const { data } = await axiosBienestarClient.get ('bienestar/especialidad')
  return data
}