import axios from 'axios'
import { useUsuario } from '../hooks/useUsuario'

export const GetAll = async () => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/Alumno`)

  return data.alumnos
}

export const GetById = async (codAlumno) => {
  const { full_path: fullPath } = useUsuario()
  const {data} = await axios.get(`${fullPath}/Alumno/obtener/${codAlumno}`)
  return data.alumno
}