import { axiosClinicaClient } from "../../api/axios"

export const getHorariosDisponibles = async ({ queryKey }) => {
  const [, fechaCita, idEspecialista] = queryKey
  const fechaCitaQuery = `fechaCita=${fechaCita}`
  const idEspecialistaQuery = `idEspecialista=${idEspecialista}`

  const { data } = await axiosClinicaClient.get(`/procesos/citas/solicitar-cita/listar-horarios?${fechaCitaQuery}&${idEspecialistaQuery}`)
  return data
}

export const createCita = async body => {
  const { data } = await axiosClinicaClient.post(`/procesos/citas/solicitar-cita/estudiante/agendar`, body)
  return data
}