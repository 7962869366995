import { CheckCircleOutlineRounded } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { DetalleSesion } from './DetalleSesion'
import { useState } from 'react'
import { useGetAlumnosBySesion } from '../../../../hooks/useSesion'

export const ButtonDetalleSesion = ({ codSesion }) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
    // refetch()
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      <Tooltip title='Detalle de la sesión'>
        <IconButton onClick={handleClickOpen}>
          <CheckCircleOutlineRounded />
        </IconButton>
      </Tooltip>
      <DetalleSesion
        open={open}
        onClose={handleClose}
        codSesion={codSesion}
      />
    </div>
  )
}
