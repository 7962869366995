import { EditRounded } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useState } from 'react'
import { useRegistrarAlumnoInfo } from '../../../../hooks/useSesion'
import { SesionAlumno } from './SesionAlumno'

export const ButtonSesionAlumno = ({ alumnoData }) => {
  const [open, setOpen] = useState(false)
  const data = []
  // const {data, refetch} = useGetAlumnosBySesion(codSesion, false)
  const { isLoading, mutate } = useRegistrarAlumnoInfo(alumnoData?.codTicket)

  const handleClickOpen = () => {
    setOpen(true)
    // refetch()
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      <Tooltip title='Editar'>
        <IconButton onClick={handleClickOpen}>
          <EditRounded />
        </IconButton>
      </Tooltip>
      <SesionAlumno mutate={mutate} isLoading={isLoading} open={open} onClose={handleClose} data={alumnoData || []} />
    </div>
  )
}
