import { Grid, IconButton, Tooltip } from '@mui/material'
import React, { useContext } from 'react'
import ContentMainBox from '../../../components/MainBox'
import { DataGridStyle } from '../../../components/DataGridStyle'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import UpdateTicket from '../CrearTicket/components/Ticket/UpdateTicket'
import { useGetAllTicket } from '../../../hooks/useTicket'
import { useAuthContext, UserContext } from '../../../context/userContext'
import { ButtonDetalleSesion } from './components/ButtonDetalleSesion'
import { RemoveCircleOutline } from '@mui/icons-material'

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'codAlumnos',
    flex: 1,
    headerName: 'Tutorado',
    type: 'string'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'tema',
    headerName: 'Objetivo de la sesión',
    // width: 300,
    flex: 2,
    type: 'string'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'fecTicket',
    headerName: 'Fecha',
    flex: 1,
    type: 'date'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'hora',
    headerName: 'Hora Inicio',
    flex: 1,
    type: 'string'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'nomSesion',
    headerName: 'Sesión',
    flex: 1,
    type: 'string'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'acciones',
    type: 'actions',
    flex: 1,
    headerName: 'Acciones',
    cellClassName: 'acciones',
    getActions: ({ id, row }) => {
      return [
        <ButtonDetalleSesion codSesion={id} />,
        <UpdateTicket data={row} id_Ticket={id} />,
        <Tooltip title='Anular'>
          <IconButton aria-label='acciones'>
            {row.estado === 'APROBADO' ? (
              <RemoveCircleOutline color='primary' />
            ) : (
              <HighlightOffRoundedIcon color='secondary' />
            )}
          </IconButton>
        </Tooltip>,
      ]
    }
  }
]

export const MisTickets = () => {
  const { user } = useAuthContext()
  const { data: tickets } = useGetAllTicket(user.codigo)
  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>Mis sesiones</div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item className='mt-4' xs={12}>
              <DataGridStyle
                height={380}
                rows={tickets || []}
                columns={columns}
                getRowId={row => row.codTikect}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}
