import axios from 'axios'
import { useUsuario } from '../hooks/useUsuario'

export const GetAll = async () => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/sesiones`)

  return data.sesiones
}

export const GetAlumnosBySesion = async (codSesion) => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/sesiones/alumnos/listar/${codSesion}`)

  return data
}

export const RegistrarInfoAlumno = async (codSesion, alumnoData) => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.put(`${fullPath}/sesiones/alumno/registro-info/${codSesion}`, alumnoData)

  return data
}

export const RegistrarInfoAlumnos = async (body) => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.put(`${fullPath}/sesiones/alumnos/registro-info`, body)

  return data
}