import { useMutation, useQuery, useQueryClient } from 'react-query'
import { GetAll, GetAlumnosBySesion, RegistrarInfoAlumno, RegistrarInfoAlumnos } from "../services/sesion"

const key = 'sesion'

export const useGetAllSesion = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled
  })

export const useGetAlumnosBySesion = (codSesion, enabled = true) =>
  useQuery([key], () => GetAlumnosBySesion(codSesion), {
    enabled
  })

export const useRegistrarAlumnoInfo = (codTicket) => {
  const queryClient = useQueryClient()

  return useMutation((body) => RegistrarInfoAlumno(codTicket, body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useRegistrarAlumnosInfo = () => {
  const queryClient = useQueryClient()

  return useMutation((body) => RegistrarInfoAlumnos(body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}