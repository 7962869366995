import {useQuery } from 'react-query'
import { GetAllEspecialidades, GetAllEspecialistas } from '../../services/bienestar/especialistas'

const keyEspecialidades = 'especialidades'
const keyEspecialistas = 'especialistas'

export const useGetAllEspecialista = (enabled = true) =>
  useQuery(keyEspecialistas, GetAllEspecialistas, {
    enabled
  })

export const useGetAllEspecialidad = (enabled = true) =>
  useQuery(keyEspecialidades, GetAllEspecialidades, {
    enabled
  })



