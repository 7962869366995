import HomeIcon from '@mui/icons-material/Home'
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded'
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded'

export function docenteNavConfig() {
  return {
    links: [
      { path: '.', name: 'Inicio', icon: HomeIcon },
      {
        path: 'creartickets',
        name: 'Crear sesiones',
        icon: ConfirmationNumberRoundedIcon
      },
      {
        path: 'mistickets',
        name: 'Mis sesiones',
        icon: ListAltRoundedIcon
      },
      {
        path: 'mihorario',
        name: 'Mi Horario',
        icon: ListAltRoundedIcon
      }
    ]
  }
}
