import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide
} from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import Input from '../../../../components/Input'
import { useAlertMessage } from '../../../../context/notifications'
// import { DataGridStyle } from '../../../../../components/DataGridStyle'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export const SesionAlumno = props => {
  const { mutate, isLoading, onClose, selectedValue, data: defaultValues, open } = props
  const { showError, showSuccess } = useAlertMessage()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      codEstudiante: defaultValues?.codAlumno ?? '',
      evaluacion: defaultValues?.evaluacion ?? '',
      estadoTicketAlumno: defaultValues?.estadoTicketAlumno ?? '',
      logroObjetivo: defaultValues?.logroObjetivo ?? '',
      comentarioAdicional: defaultValues?.comentarioAdicional ?? '',
    }
  })

  const handleClose = () => {
    onClose(selectedValue)
  }

  const onSubmit = (data) => {
    mutate(data, {
      onSuccess: () => {
        showSuccess('Registro actualizado correctamente.')
        onClose()
      },
      onError: () => {
        showError('Ocurrio un error.')
      }
    })
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          <h1 style={{ fontSize: '14pt', margin: '15px 0' }}>
            Editar información de alumno
          </h1>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Label label='Alumno' value='Willy Ttito Rojas' />
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <Input
                  id='estadoTicketAlumno'
                  label='Asistencia'
                  type='select'
                  data={[
                    { value: 4, denominacion: 'Asistió' },
                    { value: 5, denominacion: 'No asistió' }
                  ]}
                  control={control}
                  rows={2}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  id='evaluacion'
                  label='Evaluacion'
                  type='select'
                  data={[
                    { value: 1, denominacion: 'Deficiente' },
                    { value: 2, denominacion: 'Regular' },
                    { value: 3, denominacion: 'Bueno' },
                    { value: 4, denominacion: 'Muy Bueno' },
                    { value: 5, denominacion: 'Excelente' }
                  ]}
                  control={control}
                  rows={2}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  id='logroObjetivo'
                  label='Logró el objetivo'
                  type='select'
                  data={[
                    {
                      value: 1,
                      denominacion: 'Logró todos los objetivos (100%)'
                    },
                    {
                      value: 2,
                      denominacion: 'Logró la mayoría de los objetivos (75-99%)'
                    },
                    {
                      value: 3,
                      denominacion: 'Logró algunos objetivos (50-74%)'
                    },
                    { value: 4, denominacion: 'Logró pocos objetivos (25-49%)' },
                    { value: 5, denominacion: 'No logró objetivos (0-24%)' }
                  ]}
                  control={control}
                  rows={2}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  id='comentarioAdicional'
                  label='Comentarios adicionales'
                  control={control}
                  multiline={true}
                  rows={2}
                />
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <LoadingButton loading={isLoading} variant='contained' type="submit">Guardar</LoadingButton>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

const Label = ({ label, value }) => {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>{label}: </span>
      <span>{value}</span>
    </>
  )
}
